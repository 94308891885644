import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Image } from "antd";
import { useTranslation } from 'react-i18next';
import LanguagePicker from "../../components/LanguagePicker";
import Thailand from "../../../assets/thailand.png";
import UnitedStates from "../../../assets/united-states.png";
import styled from "styled-components";
import CustomFooter from "../footer";

const Main = styled.div`
   height: calc(100dvh - 105px);
`;

const LanguagePickerWrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;


const languages = [
  {
    language: 'en',
    flags: <Image
      src={UnitedStates}
      height={24}
      preview={false}
      style={{
        width: "24px",
      }}
    />,
    text: "English"
  },
  {
    language: 'th',
    flags: <Image
      src={Thailand}
      height={24}
      preview={false}
      style={{
        width: "24px",
      }}
    />,
    text: "ไทย"
  },

];

const MainLayout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const lang = Cookies.get("lang")
  const [selectedLanguage, setSelectedLanguage] = useState(lang);

  useEffect(() => {
    Cookies.set("lang", 'th')
  }, [])


  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language)
    Cookies.set("lang", language)
  };

  return (
    <div>
      <LanguagePickerWrapper>
        <LanguagePicker
          languages={languages}
          onSelect={handleLanguageSelect}
          value={selectedLanguage}
        />
      </LanguagePickerWrapper>
      <Main>
        {children}
      </Main>
      <CustomFooter />
    </div>
  );
};

export default MainLayout;
