import React from 'react';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons'; 
import styled from 'styled-components';

const LanguageSelect = styled(Select)`
  .ant-select-selector {
    border: 1px solid transparent !important;
    background: transparent !important;
  }
  .ant-select-arrow {
    color: black;
  }
`;

const { Option } = Select;

const LanguagePicker = ({ languages, onSelect, value }) => {
  const handleLanguageChange = (value) => {
    onSelect(value);
  };

  return (
    <LanguageSelect
      defaultValue={value} 
      value={value}
      onChange={handleLanguageChange}
      suffixIcon={<CaretDownOutlined />} 
    >
      {languages.map((lang) => (
        <Option key={lang.language} value={lang.language} label={lang.language}>
          {lang.flags} 
        </Option>
      ))}
    </LanguageSelect>
  );
};

export default LanguagePicker;
