import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import MainLayout from "../common/layout/main";
import { Card, Flex, Image, Row, Col, Button, Input } from "antd";
import Vavenue from "../assets/merchants/logo-vavenue.png";
import CreditCard from "../assets/credit-card.png";
import SecurityCard from "../assets/security/security_card_cvv.png";
import moment from "moment";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { CheckOutlined, CloseCircleOutlined, CreditCardOutlined, StopOutlined } from "@ant-design/icons";
import PaymentProvider from "../features/payment/provider/paymentProvider";
import PaymentCardVisa from "../features/payment/PaymentCardVisa";


const FlexStyle = styled(Flex)`

  .text-payment {
    color: #2A2C2D;
    font-family: Sarabun;
    /* font-size: 16px; */
    font-style: normal;
    font-weight: 500;
  }

  .text-transaction {
    color: #dc3545;
    font-family: Sarabun;
    font-style: normal;
    font-weight: 500;
  }
`;

const CardPayment = styled(Card)`
  .ant-card-head{
    padding: 0;
  }
  .ant-card-body {  
    background-color: #F4F4F4;
  }

  .code-security {
    color: #6D6E71;
  }

  .code-security-warning {
    color: #dc3545;
  }

  .ant-input:focus {
    border-color: #B2D234;
  }

  .ant-input:hover {
      border-color: #B2D234;
  }

  .ant-input-affix-wrapper >input.ant-input{
    width: 130px;
  }
`;

const StyledButtonIcon = styled(Button)`
  background: ${(props) => props?.background};
  color: ${(props) => props?.color};

  &.ant-btn-primary:not(:disabled):hover {
  background: ${(props) => props?.background};
  color: ${(props) => props?.color};
  }
  `;

const Title = styled.span`
  font-family: Sarabun;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;


const Payment = () => {
  const { t } = useTranslation();
  const { service_url = "" } = useParams();
  const paymentProvider = PaymentProvider()
  const [payment, setPayment] = useState(false)
  const [codeCard, setCodeCard] = useState()
  const [card, setCard] = useState(false);
  const [dataPay, setDataPay] = useState(false);
  // const [showConfirm, setShowConfirm] = useState(false);
  // const [idActive, setIdActive] = useState(false)

  const fetchValidateToken = async () => {
    const body = {
      "token": service_url,
      "type": "mPay CreditCard"
    }
    const resValidate = await paymentProvider.validateToken(body);
    setDataPay(resValidate.data)
    if (resValidate.status === 200 && resValidate.data?.card) {
      setCard(resValidate.data?.card)
    }
    else if (resValidate.status === 200 && !resValidate.data?.card) {
      fetchSubmitPayment({ "token": service_url })
    }
  };

  const fetchSubmitPayment = async (body) => {
    const response = await paymentProvider.submitPayment(body);
    if (response?.status === 200) {
      window.location.replace(response.data.formUrl)
    }
  };

  const fetchRemovePayment = async (body) => {
    await paymentProvider.removeCreditCard(body);
    fetchValidateToken()
  };

  const fetchAccessLog = async () => {
    const body = {
      "tag": "payment",
      "token": service_url
    }
    await paymentProvider.accessLogPayment(body);
  };


  useEffect(() => {
    fetchValidateToken()
    fetchAccessLog()
  }, [])

  // const data = [{ id: 1, creditNumber: "**** **** **** 8100" }, { id: 2, creditNumber: "**** **** **** 8100" }]

  // const handleConfirm = () => {
  //   const isConfirmed = window.confirm(t("press_confirm"));
  //   if (isConfirmed) {
  //     setPayment(true)
  //   } else {
  //     setPayment(false)
  //   }
  // };

  const handleSubmitCard = () => {
    const body = {
      "token": service_url,
      "card": {
        "hash_id": card.hash_id,
        "cvv": codeCard
      }
    }
    fetchSubmitPayment(body)
  };

  const handleRemoveCard = () => {
    const isConfirmed = window.confirm(t("press_confirm"));
    if (isConfirmed) {

      const body = {
        "token": service_url,
        "card": {
          "hash_id": card.hash_id
        }
      }
      fetchRemovePayment(body)
    }
  };


  return (
    dataPay?.card &&
    <MainLayout>
      <Flex vertical gap={24}>
        <FlexStyle align="center" justify="center" vertical gap={16}>
          <Image
            src={Vavenue}
            height={100}
            preview={false}
          />
          <span className="text-payment">{t("credit_card_channel")}</span>
          <span className="text-payment">{t("payment_amount")} ฿{dataPay?.amount || "1500.50"}</span>
          <span className="text-transaction">{t("complete_transaction")} {moment(dataPay?.date_expire || "2024-04-20 10:13:17").format('L h:mm')}</span>
        </FlexStyle>
        <Row>
          <Col span={8}></Col>
          <Col span={8}>
            <Flex gap={16} vertical>
              <Title>{t("credit_debit_card")}</Title>
              {/* <CardPayment title={<>{card.map((e, index) => <PaymentCardVisa index={index} id={e.id} visaNumber={e.creditNumber}/>)}</>} > */}
              <CardPayment title={<PaymentCardVisa cardType={card?.card_type || ""} creditNumber={card?.card_no || ""} cardExpire={card?.card_expire || ""} onClick={() => handleRemoveCard()} />} >
                {!payment ?
                  <Flex align='center' justify="space-between">
                    <span>*{t("primary_card")}</span>
                    <StyledButtonIcon
                      color="#000"
                      style={{ padding: "8px", height: "44px" }}
                      background="#B2D234"
                      type="primary"
                      icon={<i class="fa fa-credit-card"></i>}
                      // icon={<Image
                      //   src={CreditCard}
                      //   height={18}
                      //   preview={false}
                      // />}
                      onClick={() => setPayment(!payment)}
                    // onClick={() => handleConfirm()}
                    >
                      {t("paynow_primary_card")}
                    </StyledButtonIcon>
                  </Flex>
                  :
                  <Flex gap={8} vertical>
                    <Flex justify="space-between" align="center">
                      <Image
                        src={SecurityCard}
                        height={100}
                        preview={false}
                      />
                      <Flex gap={8} vertical justify="flex-end" align="flex-end">
                        <Flex gap={8} align="center" justify="flex-end">
                          <span className="code-security">
                            CVV/CVV2
                          </span>
                          <Input
                            suffix={codeCard ?
                              <CloseCircleOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => setCodeCard()}
                              /> :
                              <div style={{ width: "1em", height: "1em" }} />}
                            onChange={(e) => setCodeCard(e.target.value)}
                            value={codeCard}
                            maxLength={3}
                          />
                        </Flex>
                        <span className="code-security-warning">
                          *{t("security_payment_card")}
                        </span>
                      </Flex>
                    </Flex>
                    <Flex gap={8} justify="flex-end">
                      <StyledButtonIcon
                        color="#fff"
                        background="#AFAEAE"
                        type="primary"
                        icon={<StopOutlined color="#fff" />}
                        onClick={() => setPayment(!payment)}
                      >
                        {t("cancel")}
                      </StyledButtonIcon>
                      <StyledButtonIcon
                        color="#000"
                        background="#B2D234"
                        type="primary"
                        disabled={codeCard?.length === 3 ? false : true}
                        icon={<CheckOutlined />}
                        onClick={() => handleSubmitCard()}
                      >
                        {t("ok")}
                      </StyledButtonIcon>
                    </Flex>
                  </Flex>
                }
              </CardPayment>
            </Flex>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Flex align="center" justify="center">
          <StyledButtonIcon
            color="#AB3E97"
            type="text"
            icon={<i class="fa fa-money"></i>}
            onClick={() => fetchSubmitPayment({ "token": service_url })}
          >
            {t("paynow_new_card")}
          </StyledButtonIcon>
        </Flex>
      </Flex>
    </MainLayout>
  );
};

export default Payment;
