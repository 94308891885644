import { Flex, Image } from "antd";
import React, { useEffect, useState } from "react";
import MainLayout from "../common/layout/main";
import NotifyError from "../assets/images/notify_error.png";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

const MainError = styled(Flex)`
   height: calc(100dvh - 105px);

   > h1 {
    font-size: 1.0em;
    color: #a21306;
   }

   .back {
    font-size: 18px;
    padding: 8px;
   }
`;

const Error = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [seconds, setSeconds] = useState(10);
    const { state = { errorText: "" } } = location;
    const { errorText } = state || {}

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (seconds === 0) {
                window.location.replace(`https://rich-2021-www.outletlive.com/`);
                clearInterval(intervalId);
            } else {
                setSeconds(prevSeconds => prevSeconds - 1);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [seconds]);

    return (
        <MainLayout>
            <MainError gap={16} align="center" justify="center" vertical>
                <Image
                    src={NotifyError}
                    height={80}
                    preview={false}
                />
                <h1>{t(`${errorText}`)}</h1>
                <button
                    className="back"
                    onClick={() => window.location.replace(`https://rich-2021-www.outletlive.com/`)}
                >
                    {t("return_merchant")} ({seconds})
                </button>
            </MainError>
        </ MainLayout>
    );
};

export default Error;
