const theme = {
  heightCardSocial: "256px",
  black: "#000",
  white: "#FFFFFF",
  backgroundDashBoard: "#EFEFF2",
  backgroundFilter: "#F4F4F4",
  colorTextDesc: "#444450",
  navbarHeight: "50px",
  secondaryDark600: "#382A68",

  transparent: "transparent",
  // card
  colorBorderCardSocial: "#DBDBDB",
  colorBorderCardSocialRed: "#FF2643",
  colorTextInfo: "#448EF7",
  colorPositive: "#3FD495",
  colorNegative: "#FF2643",

  // button
  colorButtonDisable: "#DBDBDB",
  colorButtonBoost: "#0EA67F",
  colorButtonBoomb: "#FF2643",
  colorHoverButtonBoost: "#51C9AB",
  colorHoverButtonBomb: "#FF677C",
  shadowButton: " 1px 2px 4px 0px rgba(0, 0, 0, 0.25)",
  colorTextButton: "#2A2C2D",

  // toggle
  colorToggleDisable: "#AFAEAE",
  colorToggleDisableFilter:
    "brightness(0) saturate(100%) invert(80%) sepia(8%) saturate(25%) hue-rotate(314deg) brightness(87%) contrast(89%)",
  showColumnToggleBackground: "#d7cdf8",

  // checkbox
  colorcheckbox: "#8F79D7",
  colorcheckboxAlpha: (alpha) => `rgba(143, 121, 215, ${alpha})`,

  // table
  colortitletable: "#252527",

  // Sidebar
  colorMagenta: "#7D5DE8",
  disableSideBar: "#F5F5F5",

  // News Archives
  colorButtonDelete: "#ED1E45",
  bgButtonDelete: "#FFEEEE",
  bgButtonAdd: "#E7F6F2",
  dividerLine: "#E9E9E9",
  colorTertiary: "#6B6B6B",
  actionBorder: "#D9D9D9",
  bgTablePage: "#f4f4f4",
  errMsg: "#FF2643",

  // Status
  colorWaiting: "#7D5DE8",
  colorApproved: "#0EA67F",
  colorDone: "#008774",
  colorRejected: "#FF2643",

  colorRunning: "#448EF7",
  colorComplete: "#008774",
  colorCanceled: "#FF2643",

  secondaryBg200: "#F2EFFD",
  textInput: "#AEAFAF",
  textHoverLink: "#4F8ED7",
  shadowIcon: "0.529px 1.059px 2.118px 0px rgba(0, 0, 0, 0.25)",
  blackAlpha: (alpha) => `rgba(0,0,0, ${alpha})`,

  background: {
    facebook: "#2D85F8",
    instagram: "#EC64B8",
    twitter: "#5C5858",
    tiktok: "#519BA0",
    pantip: "#9A5EE6",
    internet: "#5BC25D",
  },
  randomColor: () =>
    [
      "#7D5DE8",
      "#5D63E8",
      "#466DA3",
      "#5D9DE8",
      "#E7721E",
      "#E8A05D",
      "#BB2F2F",
      "#E85D5D",
      "#705237",
      "#B17F4E",
      "#846C55",
      "#88837E",
      "#48687A",
      "#40909B",
      "#0EA67F",
      "#68905E",
    ].sort(() => 0.5 - Math.random())[0],
  profileColor: (index = 0) =>
    [
      "#7D5DE8",
      "#5D63E8",
      "#466DA3",
      "#5D9DE8",
      "#E7721E",
      "#E8A05D",
      "#BB2F2F",
      "#E85D5D",
      "#705237",
      "#B17F4E",
      "#846C55",
      "#88837E",
      "#48687A",
      "#40909B",
      "#0EA67F",
      "#68905E",
    ][index],
  bgFacebook: "#2D85F8",
  bgInstagram: "#EC64B8",
  bgTwitter: "#5C5858",
  bgTiktok: "#519BA0",
  bgPantip: "#9A5EE6",
  bgInternet: "#5BC25D",

  bgLike: "#E6EFFE",
  bgShare: "#FDE9EC",
  bgQuote: "#FFFBE9",
  bgReply: "#ECE7FC",
  bgPost: "#E6F6F4",
};

export default theme;
