import React, { useState } from 'react';
import styled from 'styled-components';
import { Layout, Image, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import Creditcard from "../../../assets/bank/creditcard_secure3.png";
import ModalTermsConditions from './ModalTermsConditions';

const { Footer } = Layout;

const StyledFooter = styled(Footer)`
  background-color: #e8e8e8;
  padding: 20px 0;

`;

const FooterContent = styled.div`

  .footer-span {
    margin-right: 120px;
  }
  font-size: 10px;
`;

const FooterLink = styled.a`
  /* margin: 0 5px; */
`;

const CustomFooter = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <StyledFooter>
      <FooterContent>
        <Flex gap={16} align="center" justify="center" vertical>
          <Image
            src={Creditcard}
            height={34}
            preview={false}
          />
          <Flex align="center" >
            <span className='footer-span'>Copyright 2021 OutletLive All Rights Reserved</span>
            <FooterLink onClick={() => showModal()}>{t("terms_and_conditions")}</FooterLink>
          </Flex>
        </Flex>
      </FooterContent>
      <ModalTermsConditions isModalOpen={isModalOpen} showModal={showModal} />
    </StyledFooter>
  );
};

export default CustomFooter;
