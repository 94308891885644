import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import MainLayout from "../common/layout/main";
import { Card, Flex, Image, Row, Col, Button, Input } from "antd";
import moment from "moment";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import PaymentProvider from "../features/payment/provider/paymentProvider";


const Nopay = () => {
  const { t } = useTranslation();
  const { service_url = "" } = useParams();
  const paymentProvider = PaymentProvider()

  useEffect(() => {
    checkNoPay()
  }, [])

  const checkNoPay = async () => {
    const body = {
      "token": service_url
    }
    const result = await paymentProvider.noPay(body);
    if(result?.data?.redirectUrl){
      window.location.replace(result.data.redirectUrl)
    }
  };

  return (
    <MainLayout>
    </MainLayout>
  );
};

export default Nopay;
