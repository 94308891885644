import React, { useEffect } from "react";
import styled from "styled-components";
import { Flex, Image } from "antd";
import PaymentVisa from "../../../assets/images/payment_card_visa_150x110.png";
import Trash from "../../../assets/trash-32.png";
import { useTranslation } from "react-i18next";

const CardNumber = styled(Flex)`
  /* background: ${(props) => props?.active === true ? "#E7E7E7" : "#fff"}; */
  padding: 20px 20px;
  border-radius: ${(props) => props?.firstId === true ? "8px 8px 0 0" : "0"};
`;

const FlexVisa = styled(Flex)`
    line-height: 2;

    .text-visa {
      color: #6c757d;
      font-family: Sarabun;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

  .text-visa-expired {
      color: #6c757d;
      font-family: Sarabun;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
  }
`;

const PaymentCardVisa = ({ cardType, creditNumber, cardExpire, onClick }) => {
    const { t } = useTranslation();

    const maskCreditCardNumber = (cardNumber) => {
      if (cardNumber.length < 12) {
        return "เลขบัตรเครดิตไม่ถูกต้อง";
      }

      const maskedNumber = "**** **** **** " + cardNumber.slice(-4);

      return maskedNumber;
    }

    return (
      <CardNumber justify="space-between" firstId={true} onClick={() => console.log('first')}>
        <Flex gap={8} style={{ cursor: "pointer" }} >
          <Image
            src={PaymentVisa}
            height={35}
            preview={false}
          />
          <FlexVisa gap={8} vertical>
            <span className="text-visa">{cardType} {maskCreditCardNumber(creditNumber)}</span>
            <span className="text-visa-expired">{t("expired")} {cardExpire}</span>
          </FlexVisa>
        </Flex>
        <Image
          style={{ cursor: "pointer" }}
          src={Trash}
          height={24}
          preview={false}
          onClick={onClick}
        />
      </CardNumber>
    )
  }
export default PaymentCardVisa;
