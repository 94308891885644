import { createContext, useContext, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import cryptoJs from "crypto-js";
import { useNavigate } from "react-router-dom";

const AxiosContext = createContext();


const useProviderAxios = () => {
  const uuid = uuidv4();
  const nonce = uuid.replaceAll("-", "");
  const apiKey = process.env.REACT_APP_API_KEY;
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);

  const axiosInstance = axios.create();

  function handleResponseInterceptor(response) {
    if (shouldShowLoading(response)) {
        setIsLoading(true);
    } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
    }
}

function shouldShowLoading(response) {
    const url = response.config.url;
    const functionName = url.match(/\/([^\/]+)$/)[1];
    const hasNoCardData = !response?.data?.card;
    return functionName === "validateToken" && hasNoCardData;
}

  axiosInstance.interceptors.request.use(
    (config) => {
      setIsLoading(true);
      const requestBody = config.data;
      const realToken = requestBody?.token?.split('').reverse().join('')
      const body = JSON.stringify({ ...requestBody, token: realToken })
      const base = body + nonce
      let hmac = cryptoJs.algo.HMAC.create(cryptoJs.algo.SHA256, secretKey);
      hmac.update(base);
      const sign = cryptoJs.enc.Hex.stringify(hmac.finalize());

      config.headers['x-api-key'] = apiKey;
      config.headers['x-nonce'] = nonce;
      config.headers['x-signature'] = sign;

      return config;
    },
    (error) => {
      console.log('error', error)
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      handleResponseInterceptor(response)

      return response;
    },
    (error) => {
      console.error("Response Interceptor Error:", error);
      const err = error.response;

      if (err?.status === 400) {
        // console.log('err', err)
        setIsLoading(false);
        navigate("/error", { state: { errorText: err?.data?.message } })
      }

      // if (err?.status === 401) {
      // }

      // if (err?.status === 422) {
      //   setIsLoading(false);
      //   return Promise.reject(err.data);
      // }

      if (err?.status === 500) {
        setIsLoading(false);
        navigate("/error", { state: { errorText: "Server Error" } })
        const errorMessage = new Error("Server Error");
        return Promise.reject(errorMessage);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return Promise.reject(err);
    },
  );

  return {
    axiosInstance,
    isLoading,
  };
};

const useAxios = () => {
  const { axiosInstance, isLoading } = useContext(AxiosContext);
  return {
    axios: axiosInstance,
    isLoading,
  };
};
const AxiosProvider = ({ children }) => {
  const instanceRef = useProviderAxios();

  return (
    <AxiosContext.Provider value={instanceRef}>
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosProvider, useAxios };
