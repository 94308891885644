import { Flex, Image } from "antd";
import React from "react";
import MainLayout from "../common/layout/main";
import NotifySuccess from "../assets/images/notify_success.png";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const MainSuccess = styled(Flex)`
   height: calc(100dvh - 105px);

   > span {
    font-weight: 500;
   }
`;

const Success = () => {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <MainSuccess gap={16} align="center" justify="center" vertical>
        <Image
            src={NotifySuccess}
            height={80}
            preview={false}
        />
      <span>{t("successfully")}</span>
      </MainSuccess>
    </ MainLayout>
  );
};

export default Success;
