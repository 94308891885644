import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AxiosProvider } from "../common/hooks/useProviderAxios";
import NotFound from "../pages/notFound";
import { ThemeProvider } from "styled-components";
import theme from "../themes/theme";
import Payment from "../pages/payment";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useEffect } from "react";
import Success from "../pages/success";
import Loading from "../common/components/Loading";
import Error from "../pages/error";
import Redirect from "../pages/redirect";
import Nopay from "../pages/nopay";

function App() {
  const {i18n} = useTranslation();
  const languages = Cookies.get("lang")

  useEffect(() => {
    i18n.changeLanguage(languages)
  }, [languages])
  

  return (
      <BrowserRouter>
          <AxiosProvider>
            <ThemeProvider theme={theme}>
              <Routes>
                <Route path="/" element={<Payment />} />
                <Route path="/pay/mpay/request_cc/:service_url" element={<Payment />} />
                <Route path="/pay/mpay/secure_nopay/:service_url" element={<Nopay />} />
                <Route path="/pay/mpay/return*" element={<Redirect />} />
                <Route path="/success" element={<Success />} />
                <Route path="/error" element={<Error />} />
               
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Loading />
            </ThemeProvider>
          </AxiosProvider>
      </BrowserRouter>
  );
}

export default App;
