/* eslint-disable react-hooks/rules-of-hooks */
import { useAxios } from "../../../common/hooks/useProviderAxios";

const host = process.env.REACT_APP_API_ENDPOINT;

const paymentProvider = () => {
  const { axios } = useAxios();

  const validateToken = async (body) => {
    try {
      const url = `${host}/validateToken`;
      const res = await axios.post(url, body);
      return res;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const submitPayment = async (body) => {
    try {
      const url = `${host}/payment/creditcard`;
      const res = await axios.post(url, body);
      return res;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const paymentReturn = async (body) => {
    try {
      const url = `${host}/mpay/return`;
      const res = await axios.post(url, body);
      return res;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const removeCreditCard = async (body) => {
    try {
      const url = `${host}/creditcard/remove`;
      const res = await axios.post(url, body);
      return res;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const accessLogPayment = async (body) => {
    try {
      const url = `${host}/access-log`;
      const res = await axios.post(url, body);
      return res;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  const noPay = async (body) => {
    try {
      const url = `${host}/no-pay`;
      const res = await axios.post(url, body);
      return res;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  };

  return {
    validateToken,
    submitPayment,
    paymentReturn,
    removeCreditCard, 
    accessLogPayment,
    noPay
  };
};

export default paymentProvider;
