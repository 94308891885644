import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t, } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle={t("sorry_not_found")}
        extra={
          <Button type="primary" onClick={() => navigate("/payment")}>
            {t("back_dashBoard")}
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
