import React, { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import PaymentProvider from "../features/payment/provider/paymentProvider";

const Redirect = () => {
  const paymentProvider = PaymentProvider()
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get('transactionId');

  const fetchReturn = async (body) => {
    const response = await paymentProvider.paymentReturn(body);
    if (response?.status === 200) {
        window.location.replace(response.data.redirectUrl)
      }
  };


  useEffect(() => {
   const body = {
        "txn_id": transactionId
    }
    fetchReturn(body)
  }, [transactionId])

  return (
    <span>
        Redirect...
    </span>
  );
};

export default Redirect;
