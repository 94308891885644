import { Flex } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useAxios } from "../../hooks/useProviderAxios";

const MainLoad = styled(Flex)`
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;

    .loader {
    width: 75px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid black;
    border-right-color: #0df3fc;
    animation: l2 1s infinite linear;
    }

    @keyframes l2 {to{transform: rotate(1turn)}}
`;

const Loading = ({}) => {
  const { isLoading } = useAxios();

  if (!isLoading) {
    return null;
  }

   return  (
    <MainLoad align="center" justify="center">
      <div class="loader"></div>
    </MainLoad>
  ) 
};

export default Loading;
